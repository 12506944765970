import React, {Component} from "react";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LandingComponent from "../components/LandingComponent.jsx";
import HeaderComponent from "../components/HeaderComponent.jsx";
import FooterComponent from "../components/FooterComponent.jsx";
import MenuComponent from "../components/MenuComponent.jsx";
import CateringComponent from "../components/CateringComponent.jsx";
import ContactComponent from "../components/ContactComponent.jsx";

class RouterComponent extends Component {
	render() {
		return (
			<div className="router">
				<Router>
					<HeaderComponent />
					<>
						<Switch data-nosnippet>
							<Route path="/" exact component={LandingComponent} />
							{/* <Route path="/menu" exact component={MenuComponent} /> */}
							<Route path="/catering" exact component={CateringComponent} />
							<Route path="/contact" exact component={ContactComponent} />

						</Switch>
					</>
					<FooterComponent />
				</Router>
			</div>
		);
	}
}
export default RouterComponent;
