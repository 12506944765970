import React, {Component} from 'react'
import {Container} from 'react-bootstrap'
import {p1, p2, p3, p4} from '../assets/menu/menu.js'

class MenuComponent extends Component {
  render() {
    return (
      <div className="app-window menu-window no-drag">
        <h2 className="decorated">
          <span>Menu</span>
        </h2>
        <Container className="d-flex flex-wrap justify-content-center">
          <img src={p1} className="menu-img" alt="Menu Page 1" />
          <img src={p2} className="menu-img" alt="Menu Page 2" />
          <img src={p3} className="menu-img" alt="Menu Page 3" />
          <img src={p4} className="menu-img" alt="Menu Page 4" />
        </Container>
      </div>
    )
  }
}
export default MenuComponent
