import React, {Component} from 'react'
import {Container, Button} from 'react-bootstrap'
import {Link, withRouter} from 'react-router-dom'

const headerButtonContStyle = {transition: '0s visibility, 1s opacity ease', transitionDelay: '0.1s'}

class HeaderComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTop: true,
      noScroll: true,
      mobile: false,
      expanded: false,
      loaded: false,
    }
    this.onScroll = this.onScroll.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onExpand = this.onExpand.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
    window.addEventListener('resize', this.onResize)
    this.onResize()
    this.updateButton()
    this.updateScroll()
    this.timeout = setTimeout(
      function () {
        this.setState({loaded: true})
      }.bind(this),
      100
    )
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout)
  }

  onResize() {
    if (window.innerWidth < 1000) {
      if (!this.state.mobile) {
        this.setState({loaded: false})
        this.timeout = setTimeout(
          function () {
            this.setState({loaded: true})
          }.bind(this),
          100
        )
      }
      this.setState({mobile: true})
    } else {
      this.setState({mobile: false})
    }
  }

  onScroll() {
    this.setState({isTop: window.scrollY === 0 && this.state.noScroll})
  }

  onExpand() {
    this.setState({expanded: !this.state.expanded})
  }

  componentDidUpdate(prevProps, prevState) {
    //Given the current url has changed, update the button
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0)
      this.updateButton()
      this.updateScroll()
      this.setState({
        expanded: false,
      })
    } else if (prevState.expanded !== this.state.expanded) {
      this.updateButton()
    }
  }

  //Changes whether the onScroll should be called
  updateScroll() {
    if (this.props.location.pathname !== '/') {
      this.setState({noScroll: false, isTop: false})
    } else {
      this.setState({noScroll: true, isTop: true})
    }
  }
  // Function to underline the current page's corresponding button
  updateButton() {
    var elements = document.getElementsByClassName('header-btn')

    var currLocation = this.props.location.pathname
    for (var i = 0; i < elements.length; i++) {
      var ele = elements[i]
      if (ele.pathname === currLocation && ele.href !== 'https://shop.osakakitchen.com.au/') {
        ele.style.textDecoration = 'underline'
      } else {
        ele.style.textDecoration = ''
      }
    }
  }

  render() {
    return (
      <div id="app-header" className={'fixed-top w-100 app-header no-drag no-select ' + (this.state.isTop && !this.state.mobile ? 'top' : '') + (this.state.expanded ? ' h-100' : '')}>
        {this.state.mobile ? (
          <Container style={this.state.expanded ? {} : {height: 0}}>
            <div className="d-flex justify-content-between">
              <Button as={Link} to="/" className={'m-3 '} bsPrefix="header-btn" variant="logo">
                Osaka Kitchen
              </Button>
              <Button variant="bars" className="mt-auto mb-auto" onClick={this.onExpand}>
                <i className="fas fa-bars"></i>
              </Button>
            </div>
            <div className={`header-btn-cont d-flex flex-column ${this.state.expanded ? 'visible ' : 'hidden'}`} style={this.state.loaded ? headerButtonContStyle : {}}>
              {/* <Button as={Link} to="/menu" className={'m-3'} bsPrefix="header-btn">
                Menu
              </Button> */}
               <a href="https://shop.osakakitchen.com.au" target="_blank" rel="noopener noreferrer" className={'m-3 header-btn '} onClick={this.onExpand}>
                Store <i style={{fontSize: '0.75em'}} className="fas fa-external-link-alt"></i>
              </a>
              <Button as={Link} to="/catering" className={'m-3'} bsPrefix="header-btn">
                Catering
              </Button>
              <Button as={Link} to="/contact" className={'m-3'} bsPrefix="header-btn">
                Contact
              </Button>
             
            </div>
          </Container>
        ) : (
          <Container style={{lineHeight: 'var(--header-height)'}} className="align-items-center d-flex justify-content-between">
            <div>
              <Button as={Link} to="/" className={'m-3 ' + (this.state.isTop ? 'top' : '')} bsPrefix="header-btn" variant="logo">
                Osaka Kitchen
              </Button>
            </div>
            <div>
              {/* <Button as={Link} to="/menu" className={'m-3 ' + (this.state.isTop ? 'top' : '')} bsPrefix="header-btn">
                Menu
              </Button> */}
              <a href="https://shop.osakakitchen.com.au" target="_blank" rel="noopener noreferrer" className={'m-3 header-btn ' + (this.state.isTop ? 'top' : '')}>
                Store <i style={{fontSize: '0.75em'}} className="fas fa-external-link-alt"></i>
              </a>
              <Button as={Link} to="/catering" className={'m-3 ' + (this.state.isTop ? 'top' : '')} bsPrefix="header-btn">
                Catering
              </Button>
              <Button as={Link} to="/contact" className={'m-3 ' + (this.state.isTop ? 'top' : '')} bsPrefix="header-btn">
                Contact
              </Button>
            
            </div>
          </Container>
        )}
      </div>
    )
  }
}
export default withRouter(HeaderComponent)
