import React, {Component} from 'react'
import {Container} from 'react-bootstrap'
import img1 from '../assets/catering/img1-70.jpg'
import img2 from '../assets/catering/img2-70.jpg'
class CateringComponent extends Component {
  render() {
    return (
      <div className="app-window no-drag no-select">
        <h2 className="decorated">
          <span>Catering</span>
        </h2>
        <Container className="text-center mb-5">
          <p>
            At Osaka Kitchen, we provide catering services with our great selection of delicious Japanese food.
            <br />
            We understand that every function or event is unique, so we tailor every order to your needs.
            <br />
            You will not only get great tasting food, but also delivery services to your requested location if required.
          </p>
        </Container>
        <div className="catering-img-div">
          <img src={img1} className="catering-img" alt="Sashimi Platter"></img>
          <img src={img2} className="catering-img" alt="Sushi Platter"></img>
        </div>
        <h5 className="decorated pt-5">
          <span>School catering</span>
        </h5>
        <Container className="text-center mb-5">
          <p>
            Our sister business, Momiji Japanese Catering Service, provides catering services for Primary and Secondary schools.
            <br />
            They have an exclusive menu with healthy and delicious options, to make ordering quick and easy for teachers and organisers.
            <br />
            They can also provide delivery services to your school, for a hassle-free event for students.
            <br />
            <br />
            <br />
            <br />
            Please contact us for further information at:
            <br />
            <i className="fas fa-phone mr-3"></i> 03 7035 6343 <br />
            <i className="fas fa-envelope mr-3"></i> contact@osakakitchen.com.au
          </p>
        </Container>
      </div>
    )
  }
}
export default CateringComponent
