import React, {Component} from 'react'
import {Container} from 'react-bootstrap'
class ContactComponent extends Component {
  render() {
    return (
      <div className="app-window ">
        <Container className="pb-5">
          <h2 className="decorated">
            <span>Contact Us</span>
          </h2>
          <div className="d-flex justify-content-between flex-wrap w-100 ">
            <div className="mb-5">
              <a target="_blank" rel="noopener noreferrer" href="https://maps.google.com?daddr=Osaka+Kitchen+Vermont+South+VIC+3133">
                <i className="fas fa-map-marker-alt mr-3 text-red"></i>
                Click here for directions
              </a>
              <br />
              <br />
              <br />
              <i className="fas fa-map-marked-alt mr-3"></i>Unit 2B/ 495 Burwood Hwy,Vermont South VIC 3133 <br />
              <i className="fas fa-phone mr-3"></i> 03 7035 6343 <br />
              <i className="fas fa-envelope mr-3"></i> contact@osakakitchen.com.au
            </div>
            {/* <img src={map} className="contact-map" width="350px" alt="Store Location Map"/> */}
            <div style={{backgroundColor: '#efefef'}}>
              <iframe
                title="google-maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3150.221315983899!2d145.1799159145698!3d-37.855111679744816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad63f246d2d49dd%3A0xa42928c4cd6fe15d!2sOsaka%20Kitchen!5e0!3m2!1sen!2sau!4v1606817446489!5m2!1sen!2sau"
                width={Math.min(window.innerWidth - 30, 600)}
                height="450"
                frameBorder=""
                allowFullScreen=""
                aria-hidden="false"
              />
            </div>
          </div>
        </Container>
      </div>
    )
  }
}
export default ContactComponent
