import React, {Component} from 'react'
import logo from '../assets/logo.png'

const newStoreButton = (
  <a className="new-store-cont" href="https://shop.osakakitchen.com.au" target="_blank" rel="noopener noreferrer">
    <h5 style={{border: '4px solid white'}} className="new-store-text text-light p-3 ">
      VISIT OUR ONLINE STORE
    </h5>
  </a>
)

class LandingComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFull: false,
    }
    this.onResize = this.onResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  onResize() {
    const aspectRatio = window.innerWidth / window.innerHeight
    const diff = window.innerWidth - window.innerHeight
    console.log(aspectRatio)
    console.log('diff', diff)
    console.log(diff * aspectRatio)
    if (aspectRatio > 1.3) {
      console.log('true')
      this.setState({isFull: true})
    } else {
      this.setState({isFull: false})
    }
  }

  render() {
    return (
      <div>
        <div className="landing-div no-drag no-select">
          <div className="blur-div">
            <img src={logo} alt="Logo" className="landing-logo"></img>
            {!this.state.isFull && <div className="d-flex mt-5 w-100 align-items-center justify-content-center">{newStoreButton}</div>}
          </div>
        </div>
        {this.state.isFull && <div className="bg-dark d-flex justify-content-center pt-4 pb-3">{newStoreButton}</div>}
      </div>
    )
  }
}
export default LandingComponent
