import React, {Component} from 'react'
import {Container} from 'react-bootstrap'
import {FaEnvelope, FaPhone} from 'react-icons/fa'
class FooterComponent extends Component {
  render() {
    return (
      <footer className="bg-dark text-white">
        <Container className="pb-5 pt-5">
          <div className="d-flex justify-content-between flex-wrap  text-md-left">
            <div className="pb-4" id="address">
              <h4 className="mb-4">Address</h4>
              <a className="footer-address-link" style={{color: 'white', textDecoration: 'underline'}} href="https://goo.gl/maps/MKxESCwfQYr4omZL8" target="_blank" rel="noopener noreferrer">
                Unit 2B
                <br />
                495 Burwood Hwy,
                <br />
                Vermont South, VIC 3133, <br />
                Australia
              </a>
            </div>
            <div className="pb-4" id="hours">
              <h4 className="mb-4">Business Hours</h4>
              Tues - Sat: 11:30am - 6:30pm
            </div>{' '}
            <div className="pb-4" id="contact">
              <h4 className="mb-4">Contact Us</h4>
              <FaPhone className="mr-3" /> 03 7035 6343 <br />
              <FaEnvelope className="mr-3" /> contact@osakakitchen.com.au
            </div>
          </div>
        </Container>

        <Container fluid className="bg-grey pt-3 pb-3 text-center">
          <div className="footer-socials-widget d-flex justify-content-center">
            <a
              style={{width: '64px', height: '64px'}}
              className="socials-link d-flex justify-content-center align-items-center"
              href="https://www.facebook.com/Osaka-Kitchen-101809431632440"
              target="_blank"
              rel="noopener noreferrer">
              <i style={{width: '48px', height: '48px'}} className="fab fa-facebook"></i>
            </a>
            <a
              style={{width: '64px', height: '64px'}}
              className="socials-link d-flex justify-content-center align-items-center"
              href="https://www.instagram.com/osakakitchen/"
              target="_blank"
              rel="noopener noreferrer">
              <i style={{width: '48px', height: '48px'}} className="fab fa-instagram"></i>
            </a>
          </div>
          <div className="footer-copyright">
            © 2023 Copyright <span className="company-name"> Osaka Kitchen </span> All rights reserved.{' '}
          </div>
        </Container>
      </footer>
    )
  }
}
export default FooterComponent
